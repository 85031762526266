import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/admin/company-type'

class CompanytypeService extends ApiService{
  constructor(){
    super()
  }
  getUrl(){
      return URL
  }
}

export default new CompanytypeService()