<template>
    <Overview 
        moduleName="Maatschappij"
        moduleNamePlural="Maatschappijen"
        sort="name"
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
    >
        <template #columns>
            <Column field="name" header="Naam" :sortable="true" style="width: 30%"></Column>
            <Column field="code" header="Code" :sortable="true" style="width: 90px"></Column>
            <Column field="company_types" header="Type" :sortable="false" style="width: 30%">
              <template #body="slotProps">
                  {{ showCompanyTypes(slotProps.data.company_types) }}
              </template>
            </Column>
            <Column field="logo" header="Logo" :sortable="true" style="width: 90px">
              <template #body="slotProps">
                  <i v-if='slotProps.data.logo' class='fas fa-check text-success' />
              </template>
            </Column>
            <Column field="is_provider" header="Aanbieder" :sortable="true" style="width: 120px">
              <template #body="slotProps">
                  <i v-if='slotProps.data.is_provider' class='fas fa-check text-success' />
              </template>
            </Column>
        </template>
        

        <template #form>
            <AdminPensionCompanyComponent ref="ItemForm" :itemData="item" :companyTypes="companyTypes" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
// import RegionForm from '@/components/region/RegionForm.vue';
import AdminPensionCompanyComponent from '@/components/admin/modals/AdminPensionCompanyComponent';
import pensionCompanyService from '@/services/admin/PensionCompanyService'
import companytypeservice from '@/services/admin/CompanytypeService'

export default {
    components: {
        Overview,
        Column,
        AdminPensionCompanyComponent
    },
    data() {
        return {
            item: {},
            itemService: pensionCompanyService,
            itemFormRef: null,
            companyTypes: [],
            selectFields: [
                "id",
                "name",
                "code",
                "logo",
                "is_provider",
                {"companyTypes": ["id", "name"]}
            ]
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        this.getCompanyTypes();
    },
    methods: {
        decodeBase64(encodedString){
            return atob(encodedString)
        },
        getCompanyTypes() {
            companytypeservice.index().then(response => {
                this.companyTypes = response.data
            }).catch(error => {
                this.$toast.error('Failed to get Role data')
            })
        },
        showCompanyTypes(types){
            let result = [];
            if(!types) return '';
            types.forEach(type => {
                result.push(type.name);
            });
            return result.join(', ');
        }
    }
    
}
</script>


<style lang="css">
  .companyLogo{
        width: 80px;
  }
</style>